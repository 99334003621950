<template>
  <CCard>
    <CCardHeader v-if="market">
      <div class="card-title">
        {{ market.role.name }}
      </div>
      <div class="card-header-actions text-right"></div>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol md="6">
          <img
            v-if="market.role.image_cropped"
            v-bind:src="market.role.image_cropped"
            @click="openMarket(market)"
            class="img-fluid"
          />
        </CCol>
        <CCol md="6">
          <div v-html="market.role.desc_short" />
          <br />
          <div>
            <small>{{ $t("user.marketsBox.zone") }}</small
            ><br />
            {{ market.role.desc_zone }}
          </div>
        </CCol>
      </CRow>
    </CCardBody>
    <CCardFooter>
      {{ market.role.tel }}
      <span class="float-right">
        <a :href="market.role.url" target="_blank"> {{ market.role.url }}</a>
      </span>
    </CCardFooter>
  </CCard>
</template>

<script>
export default {
  data() {
    return {
      cur_lang: this.$i18n.locale,
      market: this.mm,
      // pp_show_detail: false,
      // active_loading: false,
      // pp: this.bpp,
      // macrocategory: null,
      // parent: null,
      // category: null,
      // ageclass: null,
      // ptype: null,
      // certifications: [],
    };
  },
  model: {
    prop: "mm",
    event: "change",
  },
  props: ["mm"],
  methods: {
    openMarket(market) {
      this.$store.commit("shop/setMarket", market);
      this.$store.commit("shop/setCart", {});
      this.$router.push("shop/M" + this.market.id);
      // console.log(market)
    },
  },
  // created() {
  //   let self = this;
  //   GetOptions({
  //     dm: GetDataManager("structure_ptype"),
  //     query_cache: "structure_ptype",
  //     cur_lang: this.cur_lang,
  //   }).then((opts) => {
  //     this.macrocategory = opts.reduce(function (map, obj) {
  //       if (obj.value == self.pp.category__macrocategory_id) {
  //         map = obj.label;
  //       }
  //       return map;
  //     }, "");
  //   });
  //   GetOptions({
  //     dm: GetDataManager("structure_category_browser"),
  //     query: new Query().where("parent_id", "equal", null),
  //     query_cache: "structure_category_browser_p",
  //     cur_lang: this.cur_lang,
  //   }).then((opts) => {
  //     this.parent = opts.reduce(function (map, obj) {
  //       if (obj.value == self.pp.category__parent_id) {
  //         map = obj.label;
  //       }
  //       return map;
  //     }, "");
  //   });
  //   GetOptions({
  //     dm: GetDataManager("structure_category_browser"),
  //     query: new Query().where("parent_id", "greaterthan", 0),
  //     query_cache: "structure_category_browser_c",
  //     cur_lang: this.cur_lang,
  //   }).then((opts) => {
  //     this.category = opts.reduce(function (map, obj) {
  //       if (obj.value == self.pp.category_id) {
  //         map = obj.label;
  //       }
  //       return map;
  //     }, "");
  //   });
  //   GetOptions({
  //     dm: GetDataManager("structure_ageclass"),
  //     query_cache: "structure_ageclass",
  //     cur_lang: this.cur_lang,
  //   }).then((opts) => {
  //     this.ageclass = opts.reduce(function (map, obj) {
  //       if (obj.value == self.pp.ageclass_id) {
  //         map = obj.label;
  //       }
  //       return map;
  //     }, "");
  //   });
  //   GetOptions({
  //     dm: GetDataManager("structure_ltype"),
  //     query: new Query(),
  //     query_cache: "structure_ltype",
  //     cur_lang: this.cur_lang,
  //   }).then((opts) => {
  //     this.ptype = opts.reduce(function (map, obj) {
  //       if (obj.value == self.pp.ptype_id) {
  //         map = obj.label;
  //       }
  //       return map;
  //     }, "");
  //   });
  //   GetOptions({
  //     dm: GetDataManager("structure_certification"),
  //     reducer: function (map, obj) {
  //       map.push({ value: obj.id, label: obj.name, thumb: obj.image_thumb });
  //       return map;
  //     },
  //     reducer_cache: "certifications_i",
  //   }).then((opts) => {
  //     this.certifications = opts.reduce(function (map, obj) {
  //       if (self.pp.certifications.includes(obj.value)) {
  //         map.push({
  //           thumb: obj.thumb,
  //           label: obj.label,
  //         });
  //       }
  //       return map;
  //     }, []);
  //   });
  // },
  // computed: {
  //   titleClass(){
  //     if (this.bpp.is_active == true) {
  //       return "product_name";
  //     } else {
  //       return "product_disabled";
  //     }
  //   },
  //   cardClass() {
  //     if (this.active_loading) {
  //       return "secondary";
  //     } else if (this.bpp.is_active == true) {
  //       return "success";
  //     } else {
  //       return "danger";
  //     }
  //   },
  // },
  watch: {
    mm: function () {
      this.market = this.mm;
    },
  },
  //   "pp.is_active": function (Nv, Ov) {
  //     //   console.log("active", Nv, Ov);
  //     if (typeof Ov !== "undefined") {
  //       this.active_loading = true;
  //       let params = {
  //         id: this.pp.id,
  //         is_active: this.pp.is_active,
  //       };
  //       let self = this;
  //       GetDataManager("role_producer_products", [this.$store.state.role.id])
  //         .update("", params)
  //         .then(
  //           (e) => {
  //             //   self.pp = e;
  //             self.$emit("change", e);

  //             self.active_loading = false;

  //             this.$store.dispatch("toaster/add", {
  //               title: this.$t("role.products.showcase.product.toast.active.title"),
  //               text: this.$t("role.products.showcase.product.toast.active.text"),
  //               autohide: true,
  //             });
  //           },
  //           (reason) => {
  //             let errors = JSON.parse(reason[0].error.response);
  //             this.pp.is_active = !this.pp.is_active;
  //             this.active_loading = false;
  //             // this.dataForm.errors = errors;
  //             // this.dataForm.loading = false;
  //           }
  //         );
  //     }
  //   },
  // },
};
</script>

<style scoped>
.product_name {
  font-size: 1.8em;
}
.product_disabled {
  font-size: 1.5em;
  text-decoration: line-through;
  color: grey;
}
</style>
